import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import map from 'lodash/map'
import ReactMarkdown from 'react-markdown'

import styles from './article-preview.module.css'

export default ({ article }) => (

  <div className={styles.preview}>
    {console.log(article)}
    {article.heroImage && <Img alt="" fluid={article.heroImage.fluid} />}
    <h3 className={styles.previewTitle}>
      <Link to={`/blog/${article.slug}`}>{article.title}</Link>
    </h3>
    <small>{article.publishDate}</small>
    <ReactMarkdown source={article.description.description} />
    {map(article.tags, tag => (
      <p className={styles.tag} key={tag}>
        {tag}
      </p>
    ))}
  </div>
)
